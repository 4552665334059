import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Helmet from "react-helmet"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Footer from "../components/start/Footer"
import "../components/start/scss/index.scss"
import { RequestAccessPage } from "../components/start/views"

const Start = () => (
  <Layout clean>
    <Helmet
      bodyAttributes={{
        class: "page-start",
      }}
    />
    <SEO title="Request Access" />
    <div className="d-flex flex-column justify-content-center min-vh-100">
      <Container>
        <Row className="justify-content-center py-10">
          <Col lg="8">
            <RequestAccessPage />
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  </Layout>
)

export default Start
